.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid{
  margin-left: 0;
  margin-right: 0;
}

.no-gutter>[class^="col-"]{
  padding-left: 0;
  padding-right: 0;
}

.row{
  padding: 0px;
  margin: 0px;
}

.head-text{
    text-align: center;
    padding: 10px 20px;
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    color: #336699;
    background-color: #eee;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


.navigation-button{
    margin-right: 10px;
}

.simple-border{
    border: 1px solid black;
}



.svg_icons {
    transform: scale(1.8);
  }

  .chart-container {
    height: 550px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    cursor:pointer !important;
  }

  .inline-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }


  .btn-primary{
    background-color: #336699;
  }

  .btn-success-transaction{
    background-color: #5cb350;
    border: #5cb350;
  }

  .success-text{
    color: #5cb350;
  }

  .failure-text{
    color: red;
  }


  .top-nav-links  div {
    background-color: #eee;
    color: #111 !important;
    font-size: 20px;
    font-weight: normal;
    padding: 5px 20px 5px 20px;
    border-radius: 4px;
  }


  .sidebar{
    background-color: #f1f7fd;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - 140px);
  }
  .sidebar-links-button{
    background-color: #d4e3fc;
   margin: 20px 10px 0px 10px;
   width: 150px;
    border: none;
    padding: 5px;
    cursor: pointer;
  }

  .regitration-container{
    background-color: rgb(255, 250, 250);
    height: calc(100vh - 9rem);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .generic-form-container{
    background-color: rgb(255, 250, 250);
    /* height: calc(100vh - 9rem); */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .generic-form-container .row{
    margin-bottom: 20px;
  }
  .avatar {
    vertical-align: middle;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 20px;
  }

  .application-type-container{
    padding: 0;
    border-radius: 0.5rem;
    border: 1px solid #bbb;
    cursor: pointer;
    font-size: 28px;
    min-width: 400px;
    min-height: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    /* position: relative; */
    overflow: hidden;
    margin: 15px 0;
  }

  .application-type-image {
    width: 120%;
    height: 80%;
    object-fit: cover;
    object-position: center;
    transform: scale(1);
    transform-origin: center;
  }

  .application-type-overlay {
    /* background: rgba(0, 0, 0, 0.5); */
    background-color: rgb(190, 233, 248);
    /* color: white; */
    color: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    top: 0;
    left: 0;
    transition: background 0.3s ease;
  }

  .application-type-container:hover .application-type-overlay {
    background: rgba(0, 0, 0, 0.7);
  }

  .footer-container{
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #d4e3fc;
    color: black;
    background-color: #bee9f8
  }

  .footer-container .footer-feedback-button{
    background-color: white;
    color: black;
    border-radius: 0.25rem;
    padding: 10px;
    border: 0px;
    outline: 0px;
    cursor: pointer;
  }

  .footer-container .footer-link-list{
    list-style-type: none;
    margin-left: -2rem;
  }

  .FTC_app_container{
    height: 300px;
  }

  .CAC_app_container{
   height: 300px;
   vertical-align: middle;
  }


  .top-nav-links a{
    font-weight: 800;
  }

  .bg-image{
    /* background: url('../images/Background FTC.png'); */
    /* background-size: cover; */
    background-color: #F2F8FA;
  }

  .RSPBprogressBar .RSPBstep{
    font-size: 22px !important;
    font-weight: 600;
  }
  
.required::after {
  content: " *";
  color: #e32;
  position: absolute;
  margin: -8px 0px 0px 0px;
  font-size: xx-large;
  padding: 0 0 0 0;
}