.form-container{
    display: block;
    margin-bottom: 60px;
}

.register-form-container{
    width: 40%;
    margin: auto;
    padding: 2rem;
    background-color: #eee;
    border-radius: 2px;
}

.login-form-container{
    width: 40%;
    margin: auto;
    padding: 2rem;
    background-color: #eee;
    border-radius: 2px;
}

.form-top-heading{
    color: royalblue;
    font-size: 32px;
    font-weight: 600;
    text-align: center;

}

.form-heading{
    font-size: 30px;
    font-weight: 500;

}

.sub-form-header{
    font-size: 28px !important;
}

.col span {
    color: red;
}

label{
    margin-top: 0.6em;
    margin-bottom: 0.2em !important;
    font-weight: 600;
}

input {
    border-width: 1px;
    border-radius: 0px;
}


/* to remove the default bootstap focus */
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
select.form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
  border: none;
  border-bottom: 2px solid #369;
}