.division{
    margin: 10px;
}

.division-selection{
    color: white;
    font-size: 32px;
    font-weight: 600;
    margin: 10px;
    padding: 40px;
    background-color: rgb(35, 122, 163) !important;
    text-align: center;
}